import React from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { Layout, SEO } from '../components';
import { PDFViewer } from '../components/PDFViewer';

const isBrowser = typeof window !== 'undefined';

export default function EmbededDocumentPage() {
    let params;
    let id;
    let videoGuide;

    if (isBrowser) {
        params = new URLSearchParams(window.location.search);
        id = params.get('id');
        videoGuide = params.get('videoGuide');
    }

    const user = useSelector((state) => state.user.data);
    const isSubscribed = user?.subscribed ?? false;

    const videoRef = React.useRef(null);
    const [signedUrl, setSignedUrl] = React.useState('');
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        const get_url = async () => {
            const jwt = localStorage.getItem('jwt');
            if (!jwt) throw 'No JWT';
            const response = await axios.get(
                `${process.env.GATSBY_BACKEND_URL}/api/documents/get-subscription-doc?id=${id}`,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/pdf',
                        'X-MMC-Authorization': `Bearer ${jwt}`,
                    },
                },
            );
            if (response.data?.message) {
                setError(response.data.message);
                return;
            }
            return response.data.url;
        };

        get_url()
            .then(setSignedUrl)
            .catch(() => navigate('/'));
    }, []);

    return (
        <Layout>
            <SEO title="Document" noIndex={true} />
            <div className="container content-section mb-2 p-0">
                {videoGuide && (
                    <button
                        className="button-blue w-button ml-0.5"
                        onClick={() => videoRef.current.scrollIntoView({ behavior: 'smooth' })}
                    >
                        View video
                    </button>
                )}
                {!isSubscribed ? (
                    <p>You must be subscribed to view this document.</p>
                ) : error ? (
                    <p>{error}</p>
                ) : signedUrl ? (
                    <PDFViewer url={signedUrl} fullScreen={true} hideWaterMark={true} print={true} />
                ) : null}
                {videoGuide && (
                    <div className="srow pb-1" ref={videoRef}>
                        <div className="scolumn">
                            <h1 className="content-h2">Video Guide</h1>
                            <div className="embedded-video-wrapper mb-1">
                                <iframe
                                    src={videoGuide.replace('watch?v=', 'embed/')}
                                    className="embedded-video"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    scrolling="no"
                                    // height="100%"
                                    width="100%"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}
